import React from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'
import ReactPlayer from "react-player"

import { MapContainer, Marker, Popup, TileLayer, Polyline } from "react-leaflet";
import { Icon, divIcon } from "leaflet";
import styles from './Route.module.sass'
import 'leaflet/dist/leaflet.css';
import { withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import { getPage } from 'actions'

//const position = [51.505, -0.09]
/*
const polyline = [
    [51.505, -0.09],
    [51.51, -0.1],
    [51.51, -0.12],
]
*/

const limeOptions = { color: 'blue' }

const Route = ({ data, getPage, location }) => {
    //const location = useLocation()

    console.log(location.pathname)

    useEffect(() => {
        console.log('ss')
        getPage(location.pathname)
    }, [])

    if (!data) {
        return <div className={styles.route}><span className="loading">Loading...</span></div>
    }

    const position = [data.route.center_lat, data.route.center_lng]
    const polyline = data.route.markers.map(marker => ([marker.lat, marker.lng]));

    if (data.route_loop && data.route.markers) {
        polyline.push(data.route.markers[0])
    }

    let markers = data.route.markers.filter((item, index) => index === 0 || (index === data.route.markers.length - 1 && !data.route_loop))




    const mixcloud = encodeURIComponent(data.audio.replace('https://www.mixcloud.com', ''))

    return (
        <div className={styles.route}>
            <div className="container map">
                <div>
                    <MapContainer
                        center={position}
                        zoom={data.route.zoom}
                        scrollWheelZoom={false}
                        style={{
                            height: "100%",
                            width: '100%'
                        }}>
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Polyline pathOptions={limeOptions} positions={polyline} />
                        {markers.map((place, i) => (
                            <Marker
                                key={i}
                                position={[
                                    place.lat,
                                    place.lng
                                ]}
                                title={place.title}
                                icon={new divIcon({
                                    className: 'my-div-icon',
                                    html: `<div>${i === 0 ? 'A' : 'B'}</div>`,
                                })}
                            />
                        ))}
                    </MapContainer>
                </div>
            </div>
            <div className="container audio">
                <div>
                    <ReactPlayer
                        url={data.audio}
                        controls
                        width="100%"
                        height="120"
                    />
                </div>
            </div>
            <div className="container image">
                {data.image &&
                    <div>
                        <div className="bg">
                        </div>
                        <div className={'image' + (data.image.height < data.image.width ? ' landscape' : '')} style={{ backgroundImage: `url(${data.image.path})` }}>
                        </div>
                    </div>
                }
            </div>

            <div className="container text">
                <div>
                    <div dangerouslySetInnerHTML={{ __html: data.text }}></div>
                    <div className="flex">
                        {data.image &&
                            <div className="image">
                                <img src={data.image.path} />
                            </div>
                        }
                        <div>
                            <ul className="links">
                                {data.links.map((item, index) => (
                                    <li><a href={item.url}>{item.name}</a></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state, props) => {

    const { pages } = state.pages

    const data = pages.find(page => page.path === props.location.pathname)

    console.log(pages)

    return {
        data
    }
}

const mapDispatchToProps = dispatch => ({
    getPage: (path) => dispatch(getPage(path)),
})

const enhance = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(Route);