import React from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { useEffect } from 'react';
import { getPage } from 'actions'
import { withRouter } from 'react-router-dom';

import styles from './About.module.sass'


const About = ({data, getPage, location}) => {
    //const location = useLocation()

    console.log(location.pathname)

    useEffect(()=> {
        console.log('ss')
        getPage(location.pathname)
    }, [])

    if(!data) {
        return null
    }

    return (
        <div className={styles.about}>
            {data.texts.map((item, index) => (
                <div key={index} dangerouslySetInnerHTML={{__html: item}}></div>
            ))}
            {data.logos &&
                <div className="logos">
                    <div>
                    {
                        data.logos.map((item, index) => (
                            <div>
                                {item.link &&
                                    <a href={item.link} target="_blank" />
                                }
                                <img src={item.url} />
                            </div>
                        ))
                    }
                    </div>
                </div>
            }
        </div>
    );
};

const mapStateToProps = (state, props) => {
   
    const { pages } = state.pages
    
    const data = pages.find(page => page.path === props.location.pathname) 

    console.log(pages)

	return {
        data
	}
}

const mapDispatchToProps = dispatch => ({
    getPage: (path) => dispatch(getPage(path)),
})

const enhance = compose(
    withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(About);