
import './App.sass';
import { connect } from 'react-redux'
import { useState, useEffect } from 'react';
import { Switch, Route, NavLink } from 'react-router-dom'
import Header from './components/header/Header'
import RoutePage from './pages/Route'
import AboutPage from './pages/About'
import { getPage } from 'actions'


const points = [
  {
    points: '356.92 180.32 379.88 170.86 407.8 167.71 406 180.32 416.36 183.47 414.1 193.83 386.64 226.7 369.53 231.2 363.22 224.45 350.17 224.9 343.41 249.66 355.12 265.87 343.41 277.13 343.41 284.33 347.46 290.19 329.9 293.79 308.29 347.82 301.99 345.12 327.2 286.58 323.15 277.58 292.98 279.83 285.77 291.09 274.52 291.54 274.52 281.63 265.96 272.63 281.27 257.77 335.76 246.51 333.5 220.84 341.16 211.39 391.14 210.94',
    points2: '167.44 186.76 199.38 173.61 238.21 169.23 235.71 186.76 250.11 191.15 246.98 205.55 208.78 251.28 184.98 257.54 176.21 248.14 158.04 248.77 148.65 283.22 164.93 305.77 148.65 321.43 148.65 331.45 154.28 339.59 129.86 344.6 99.79 419.76 91.03 416 126.1 334.58 120.46 322.05 78.5 325.18 68.48 340.84 52.82 341.47 52.82 327.69 40.92 315.16 62.22 294.49 138 278.83 134.87 243.13 145.52 229.98 215.04 229.35'
  },
  {
    points: '939.22 329.69 958.23 278.41 952.18 272.23 1021.15 215.71 1021.78 209.85 1040.41 198.44 1047.32 185.76 1072.86 165.22 1080.98 152.28 1101.76 150.19 1111.01 155.94 1108.51 160.8 1099.73 154.32 1098.79 159.35 1109.35 171.17 1114 172.07 1120.97 166.11 1131.93 163.35 1123.89 171.96 1133.63 184.08 1137.39 214.91 1168.72 217.92 1180.77 212.11 1211.81 224.57 1226.95 223.45 1234.5 208.47 1243.59 201.16 1254.78 200.01 1259.67 195.01 1260.52 187.2 1265.83 178.29 1280.44 174.74 1289.23 184.78 1303.13 177.07 1296.87 168.77 1298.4 160.26 1318.46 153.75 1332.72 161.25 1306.21 199.19 1289.19 200.23 1278.39 190.22 1286.16 186.04',
    points2: '425.31 413.38 441.92 357.37 435.04 351.2 504.53 286.05 504.79 279.76 523.83 266.28 530.3 252.26 556.07 228.56 563.8 214.18 585.8 210.47 596.07 215.94 593.75 221.31 583.93 215.02 583.28 220.45 595.39 232.29 600.4 232.92 607.41 226.07 618.9 222.35 610.94 232.1 622.19 244.33 628.39 276.93 662 277.91 674.43 270.87 708.41 281.94 724.46 279.67 731.45 263.16 740.62 254.72 752.47 252.69 757.32 247.02 757.67 238.63 762.7 228.76 778.02 223.94 788.1 234.02 802.37 224.81 795.11 216.41 796.14 207.22 817.05 198.86 832.79 205.83 807.23 248.16 789.16 250.49 776.94 240.59 784.92 235.57'
  },
  {
    points: '1050.01 434.95 1017.46 403.95 940.28 378.88 946.44 359.75 995.14 370.31 998.33 363.49 1006.04 363.93 1022.08 350.52 1020.54 332.27 1031.97 323.69 1040.11 343.48 1037.69 364.15 1008.01 387.02 999.87 379.32 993.94 382.62 1001.19 389 1011.53 389 1031.54 377.12 1070.67 416.04 1066.72 421.54 1071.99 430.99 1040.33 447.93 1022.3 444.41 1009.55 433.41 985.35 435.39 936.33 480.03 896.53 501.8 877.62 531.7 877.62 544.67',
    points2: '754.74 611.76 724.69 583.13 653.44 559.99 659.12 542.33 704.09 552.07 707.03 545.78 714.15 546.19 728.96 533.8 727.54 516.95 738.09 509.04 745.61 527.31 743.37 546.39 715.97 567.5 708.45 560.4 702.97 563.44 709.67 569.33 719.21 569.33 737.69 558.37 773.82 594.3 770.17 599.38 775.04 608.11 745.81 623.74 729.16 620.49 717.39 610.34 695.04 612.16 649.78 653.38 613.04 673.48 595.58 701.09 595.58 713.06'
  },
  {
    points: '1185.33 791.55 1170.66 791.55 1171.29 720.68 1180.82 704.33 1176.49 682.78 1157.91 662.59 1133.26 654.29 1074.05 669.89 1052.37 649.7 1060.05 633.23 1102.29 607.47 1105.76 598.05 1089.04 561.14',
    points2: '773.01 1259.74 753.66 1259.74 754.49 1166.25 767.07 1144.68 761.35 1116.24 736.84 1089.61 704.32 1078.66 626.21 1099.25 597.61 1072.61 607.74 1050.88 663.46 1016.89 668.04 1004.47 645.98 955.77'
  },
  {
    points: '654.26 573.29 688.71 557.67 706.26 557.67 724.02 574.37 724.02 588.06 718.03 593.84 713.54 593.84 712.68 634.93 716.62 641.35 676.94 657.4 665.81 656.33 664.1 662.32 681.22 699.13 697.7 720.11 693.21 729.09 679.72 730.38 672.02 735.73 672.02 739.37 665.6 744.93 665.38 753.06 644.2 747.93 643.13 744.08 635.64 739.79 640.77 732.95 635.64 725.03 627.51 723.96 588.56 764.83 569.94 773.39 555.6 752.63 563.52 730.59 566.3 704.05 574.43 694.64 577.43 662.97 571.89 647.99 606.53 603.47 624.08 598.98 654.26 573.29',
    points2: '356.17 1089.84 401.75 1069.17 424.97 1069.17 448.47 1091.25 448.47 1109.37 440.54 1117.02 434.6 1117.02 433.46 1171.38 438.67 1179.88 386.18 1201.11 371.46 1199.7 369.19 1207.62 391.84 1256.32 413.64 1284.07 407.7 1295.96 389.86 1297.66 379.67 1304.74 379.67 1309.56 371.17 1316.92 370.89 1327.68 342.86 1320.88 341.44 1315.78 331.53 1310.12 338.33 1301.06 331.53 1290.59 320.77 1289.17 269.24 1343.25 244.61 1354.58 225.64 1327.11 236.11 1297.95 239.79 1262.84 250.55 1250.38 254.52 1208.47 247.19 1188.65 293.02 1129.76 316.24 1123.81 356.17 1089.84'
  },
  {
    points: '584.84 388.29 578.12 392.38 578.12 399.69 574.12 403.78 574.12 419.56 579.48 421.61 574.12 443.54 560.6 456.69 524.91 448.21 505.33 432.14 502.99 427.17 499.48 428.92 489.54 423.95 472.88 422.49 434.58 436.23 403.6 452.6 383.72 450.26 380.5 468.68 386.93 476.28 391.61 475.11 396.87 480.08 406.23 478.32 423.77 457.86 445.98 445.29 467.03 450.55 473.76 459.32 478.73 456.4 485.74 459.32 495.1 441.49 534.27 464.88 562.92 467.51 587.47 452.89 593.9 447.63 592.15 437.11 585.13 437.11 587.47 390.33',
    points2: '473.47 667.55 465.3 672.52 465.3 681.4 460.45 686.37 460.45 705.54 466.96 708.02 460.45 734.65 444.03 750.62 400.69 740.33 376.91 720.8 374.07 714.77 369.81 716.9 357.74 710.86 337.5 709.09 291 725.77 253.37 745.65 229.23 742.81 225.32 765.18 233.13 774.41 238.81 772.99 245.2 779.02 256.56 776.89 277.86 752.04 304.84 736.78 330.4 743.17 338.57 753.82 344.6 750.27 353.12 753.82 364.48 732.16 412.05 760.56 446.84 763.76 476.66 746.01 484.47 739.62 482.34 726.84 473.82 726.84 476.66 670.04'
  },
  {
    points: '61.16 542.77 72.27 546.94 80.72 538.34 91.33 545.4 93.67 552.61 114.53 561.11 125.68 561.11 132.34 562.17 157.88 580.23 160.75 576.51 164.89 578.28 172.44 586.6 172.44 591.21 185.22 592.62 189.71 590.5 190.79 564.29 197.26 548.36 204.28 545.4 211.11 545.4 225.86 530.65 210.57 517.01 212.73 508.34 210.93 495.06 213.45 493.82 213.45 490.98 235.39 497.89 258.05 504.62 264.53 504.44 269.2 503.38 277.48 503.73 283.95 498.6 302.3 490.1',
    points2: '35.8 957.08 46.91 961.25 55.37 952.65 65.98 959.71 68.31 966.91 89.18 975.41 100.33 975.41 106.98 976.48 132.52 994.54 135.4 990.82 139.53 992.59 147.09 1000.91 147.09 1005.52 159.86 1006.93 164.35 1004.81 165.43 978.6 171.91 962.67 178.92 959.71 185.76 959.71 200.5 944.96 185.22 931.32 187.38 922.65 185.58 909.37 188.1 908.13 188.1 905.29 210.04 912.2 232.7 918.93 239.17 918.75 243.85 917.69 252.12 918.04 258.6 912.91 276.94 904.41'
  }
]


function App({ data, getPage }) {
  const [activeRoute, setActiveRoute] = useState(null)
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    console.log('ss')
    getPage('/')
  }, [])

  useEffect(() => {
    if (data?.routes) {
      const combinedRoutes = data.routes.map((item, index) => (
        { ...item, points: points[index].points, pointsMobile: points[index].points2 }
      ))
      setRoutes(combinedRoutes);
    }
  }, [data])

  return (
    <div className="app">
      <Header
        routes={routes}
        activeRoute={activeRoute}
        setActiveRoute={(index) => {
          console.log(index)
          setActiveRoute(index) 
        }} />
      <Switch>
        <Route path='/route/:route' component={RoutePage} />
        <Route path='/about' component={AboutPage} />
      </Switch>
    </div>
  );
}

const mapStateToProps = (state, props) => {

  const { pages } = state.pages

  const data = pages.find(page => page.path === '/')

  return {
    data
  }
}

const mapDispatchToProps = dispatch => ({
  getPage: (path) => dispatch(getPage(path)),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)


